import React from "react";
import styles from "./Question.module.css";

export function Question(props) {
  const isActivePlayer = props.isActivePlayer;
  let classNames = styles.question;

  if (isActivePlayer) {
    classNames += " " + styles.isActivePlayer;
  }

  return <div className={classNames}>{props.children}</div>;
}
