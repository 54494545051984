import React from "react";
import styles from "./NextButton.module.css";
import topArrow from "./top-arrow.svg";
// import { ReactComponent as Logo } from "./top-arrow.svg";

export class NextButton extends React.Component {
  render() {
    const isHostTurn = this.props.isHostTurn;
    const animationClass = isHostTurn ? styles.rotateAnimation : "";

    return (
      <div
        className={`${styles.nextButton} ${animationClass}`}
        onClick={this.props.onClick}
      >
        <img src={topArrow} alt="arrow" className="arrow" />
        {/* <Logo fill="red" stroke="green" /> */}
      </div>
    );
  }
}
