import React from "react";
// import logo from './logo.svg';
import "./App.css";
import styles from "./App.module.css";
import { Question } from "./Question";
import { NextButton } from "./NextButton";

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const loadJson = (url, callbackFunction) => {
  fetch(url)
    .then((response) => handleErrors(response))
    .then((response) => response.json())
    .then((jsonData) => callbackFunction(null, jsonData))
    .catch((error) => callbackFunction(error));
};

const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    const storedLanguage = localStorage.getItem("language");

    this.state = {
      questions: null,
      currentQuestionIndex: null,
      language: storedLanguage || "en",
      isHostTurn: true,
    };
  }

  componentDidMount() {
    // Fetch json.
    loadJson("questions.json", (error, jsonData) => {
      if (error) {
        // Handle errors.
        console.log(error);
        return;
      }

      const questions = shuffle([...jsonData]);

      // Save the json data in the component state.
      this.setState({
        questions: questions,
        currentQuestionIndex: 0,
      });
    });
  }

  handleNextButtonClick = () => {
    const currentQuestionIndex = this.state.currentQuestionIndex;
    const newIndex = currentQuestionIndex + 1;

    this.setState({
      currentQuestionIndex: newIndex,
      isHostTurn: !this.state.isHostTurn,
    });
  };

  handleLanguageChange = (event) => {
    const language = event.target.value;
    this.setState({ language: language });
    localStorage.setItem("language", language);
  };

  render() {
    const isHostTurn = this.state.isHostTurn;
    const questions = this.state.questions;
    const language = this.state.language;
    let currentQuestion = "...";
    const divStyle = {
      color: "green",
    };

    if (questions !== null && questions.length) {
      const filteredQuestions = questions.filter((question) => {
        return question.question[language] !== undefined;
      });

      const nbQuestions = filteredQuestions.length;

      if (nbQuestions) {
        const index = this.state.currentQuestionIndex % nbQuestions;
        currentQuestion = filteredQuestions[index].question[language];
      }
    }

    return (
      <div className="app noselect">
        {/* <div className="hidden">
          language:&nbsp;
          <select onChange={this.handleLanguageChange} value={language}>
            <option value="fr">fr</option>
            <option value="en">en</option>
          </select>
        </div> */}
        <Question style={divStyle} isActivePlayer={!isHostTurn}>
          {currentQuestion}
        </Question>
        <NextButton
          isHostTurn={isHostTurn}
          onClick={this.handleNextButtonClick}
        />
        <Question isActivePlayer={isHostTurn}>{currentQuestion}</Question>
      </div>
    );
  }
}

export default App;
